<template>
  <div v-frag>
    <v-container class="py-10 px-5" fluid>
      <v-row align="center" justify="end" class="mx-0">
        <v-col cols="12" sm="6">
          <v-btn color="primary" @click="$router.push({ name: 'formBuilder-editor', params: { formId: 'uj-urlap' }})">
            Új hozzáadása
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pa-0">
          <v-text-field
              v-model="search"
              aria-label="Keresés"
              label="Keresés"
              filled
              prepend-inner-icon="mdi-magnify"
              persistent-hint
              clearable
              hint="Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="forms"
          :search="search"
          :loading="loadingData"
          loading-text="Adatok betöltése folyamatban... Kérjük várjon!"
          no-data-text="Nincsenek szerkeszthető űrlapok!"
          :no-results-text="`A keresett ${search} kifejezés nem található`"
      >
        <template v-slot:item.actions="{item}">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      v-on="on">
                mdi-content-copy
              </v-icon>
            </template>
            <span>Űrlap duplikálása</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon class="mr-3"
                      @click="editItem(item.id)"
                      v-on="on">
                mdi-pencil
              </v-icon>
            </template>
            <span>Űrlap szerkesztése</span>
          </v-tooltip>
          <v-tooltip top v-if="item.isErasable">
            <template v-slot:activator="{on}">
              <v-icon @click="editItem(item.id)"
                      v-on="on">
                mdi-delete
              </v-icon>
            </template>
            <span>Űrlap törlése</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'FormBuilderHome',
  components: {
    //
  },
  props: {
    //
  },
  mounted() {
    setTimeout(this.loadTableData, 1500);
  },
  data() {
    return {
      search: null,
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Űrlap neve', value: 'formName' },
        { text: 'Státusz', value: 'status' },
        { text: 'Utolsó módosítás', value: 'lastModified' },
        { text: 'Utoljára módosította', value: 'userName' },
        { text: '', value: 'actions' },
      ],
      forms: [],
      loadingData: true,
    };
  },
  methods: {
    loadTableData() {
      this.forms = [
        {
          id: 1,
          formName: 'Basic',
          status: 'Template',
          lastModified: '2021-03-23',
          userName: 'admin',
        },
      ];
      this.loadingData = false;
    },
    editItem(formId) {
      this.$router.push({ name: 'formBuilder-editor', params: { formId } });
    },
  },
};
</script>

<style scoped>

</style>
