var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('v-container',{staticClass:"py-10 px-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center","justify":"end"}},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'formBuilder-editor', params: { formId: 'uj-urlap' }})}}},[_vm._v(" Új hozzáadása ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"aria-label":"Keresés","label":"Keresés","filled":"","prepend-inner-icon":"mdi-magnify","persistent-hint":"","clearable":"","hint":"Begépeléssel rá tud keresni a táblázatban megadott adatok bármelyikére"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.forms,"search":_vm.search,"loading":_vm.loadingData,"loading-text":"Adatok betöltése folyamatban... Kérjük várjon!","no-data-text":"Nincsenek szerkeszthető űrlapok!","no-results-text":("A keresett " + _vm.search + " kifejezés nem található")},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3"},on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v("Űrlap duplikálása")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-3",on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Űrlap szerkesztése")])]),(item.isErasable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Űrlap törlése")])]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }